<template lang="pug">
.lh-the-paginator(v-if="isPaginatorVisible")
  .lh-the-paginator__row
    component(
      v-if="!isFirstPageActive",
      class="lh-the-paginator__arrow-icon lh-the-paginator__arrow-icon--left"
      :class="{ 'lh-the-paginator__arrow-icon-hidden' : isFirstPageActive }",
      :is="linkComponent",
      :type="linkComponent === 'button' ? 'button' : undefined",
      :href="getNuxtLink(activePage - 1)",
      @click="handlePageLinkClick(+activePage - 1)"
    )
      include ../_assets/left-arrow-icon.svg

    template(v-for="type in types")
      .lh-the-paginator__cells(data-jest="link-list" :class="type")
        template(v-for="item of createItems(type)")
          component(
            v-if="item.type === 'link'",
            :data-jest="`link-${item.number}`",
            :key="'link-' + item.id",
            :is="linkComponent",
            :type="linkComponent === 'button' ? 'button' : undefined",
            class="lh-the-paginator__cell"
            :class="{ 'lh-the-paginator__cell--active': item.active }",
            :href="getNuxtLink(item.number)",
            @click="handlePageLinkClick(+item.number)"
          ) {{ item.number }}
          .lh-the-paginator__ellipsis(
            v-else-if="item.type === 'ellipsis'",
            data-jest="ellipsis",
            :key="'ellipsis-' + item.id"
          ) ...

    component(
      v-if="!isLastPageActive",
      class="lh-the-paginator__arrow-icon lh-the-paginator__arrow-icon--right"
      :class="{ 'lh-the-paginator__arrow-icon-hidden' : isLastPageActive }",
      :is="linkComponent",
      :type="linkComponent === 'button' ? 'button' : undefined",
      :href="getNuxtLink(activePage + 1)",
      @click="handlePageLinkClick(+activePage + 1)"
    )
      include ../_assets/right-arrow-icon.svg
</template>

<script lang="ts">
  import type { PropOptions } from 'vue';
  import { getId, trailingSlash } from '~/utils';

  type StructureItem = {
    id: string;
    type: 'link' | 'ellipsis';
    number?: number;
    active?: boolean;
  };

  export default defineNuxtComponent({
    name: 'ThePaginator',
    props: {
      totalPagesCount: { type: Number, default: null } as PropOptions<number>,
      activePage: { type: Number, required: true } as PropOptions<number>,
      routeBeginning: { type: String, default: '' } as PropOptions<string>,
      pagePrefix: { type: String, default: '?page=' } as PropOptions<string>,
      isInternalLink: { type: Boolean, default: true } as PropOptions<boolean>,
      isTrailingSlash: { type: Boolean, default: false } as PropOptions<boolean>,
      noQueryUpdate: { type: Boolean, default: false } as PropOptions<boolean>,
      hasNextPage: { type: Boolean, default: null } as PropOptions<boolean>,
    },
    computed: {
      types: () => ['desktop', 'mobile'],
      isFirstPageActive(): boolean {
        return +this.activePage === 1;
      },
      isLastPageActive(): boolean {
        return this.hasNextPage !== null
          ? !this.hasNextPage
          : this.activePage === this.totalPagesCount;
      },
      linkComponent(): string {
        if (this.noQueryUpdate) {
          return 'button';
        }
        return 'a';
      },
      isPaginatorVisible(): boolean {
        return this.hasNextPage !== null
          ? true
          : this.totalPagesCount
            ? this.totalPagesCount > 1
            : false;
      },
    },
    methods: {
      getNuxtLink(page: number): string | void {
        if (this.linkComponent === 'button') {
          return undefined;
        }

        const routeWithSlash = trailingSlash(this.routeBeginning);

        const { pagePrefix, isTrailingSlash } = this;

        return page > 1
          ? `${routeWithSlash}${pagePrefix}${page}${isTrailingSlash ? '/' : ''}`
          : routeWithSlash;
      },
      handlePageLinkClick(pageNumber: number): void {
        this.$emit('page-link-click', pageNumber);
      },
      createItems(type: 'desktop' | 'mobile'): StructureItem[] {
        const { activePage, totalPagesCount } = this;

        if (!totalPagesCount) {
          const id = getId();
          return [{ id, type: 'link', number: activePage, active: true }];
        }

        // правила описаны в ThePaginator.md и в тестах
        const structure: StructureItem[] = [];
        const addStructureItem = (
          type: 'link' | 'ellipsis',
          number?: number,
          active?: boolean,
        ): void => {
          const id = getId();
          if (type === 'link') {
            structure.push({
              number,
              type,
              active,
              id,
            });
          } else {
            structure.push({
              id,
              type,
            });
          }
        };

        const firstTypePage = type === 'mobile' ? 2 : 5;
        const firstTypeCount = type === 'mobile' ? 3 : 7;

        if (totalPagesCount <= firstTypeCount + 1) {
          for (let i = 1; i <= totalPagesCount; i += 1) {
            addStructureItem('link', i, i === activePage);
          }
          return structure;
        }

        if (activePage <= firstTypePage) {
          for (let number = 1; number <= firstTypeCount; number += 1) {
            addStructureItem('link', number, number === activePage);
          }
          addStructureItem('ellipsis');
          addStructureItem('link', totalPagesCount, false);
        } else if (activePage > totalPagesCount - firstTypePage) {
          addStructureItem('link', 1, false);
          addStructureItem('ellipsis');
          for (let i = totalPagesCount - firstTypeCount + 1; i <= totalPagesCount; i += 1) {
            addStructureItem('link', i, i === activePage);
          }
        } else {
          addStructureItem('link', 1, false);
          addStructureItem('ellipsis');
          if (type === 'desktop') {
            [-2, -1, 0, 1, 2].forEach((item) => {
              const number = activePage + item;
              addStructureItem('link', number, number === activePage);
            });
          } else {
            addStructureItem('link', activePage, true);
          }
          addStructureItem('ellipsis');
          addStructureItem('link', totalPagesCount, false);
        }

        return structure;
      },
    },
  });
</script>
<style lang="scss" src="./ThePaginator.scss"></style>
